import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Card from "../../../components/card";
import { _delete, _get, _post, _put } from "../../../apiClient";
import Avatar from "../app/avatar/avatar";
import Toggle from "../app/Pipe/subcription";
import DeliveryActive from "../app/Pipe/deliveryStatus";
import Loader from "../Loader";

const DeliveryAllBoyList = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [userList, setUserList] = useState([]);
    const [filter, setFilter] = useState('all');
    const [message, setMessage] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deliveryToDelete, setDeliveryToDelete] = useState(null);
    const [successMsg, setSuccessMsg] = useState('');
    const [showModal, setShowModal] = useState(false);

    const history = useHistory();
    const token = localStorage.getItem("token");

    useEffect(() => {
        if (token) {
            fetchData();
        }
    }, [token]);

    useEffect(() => {
        filterUsers(data);
        setCurrentPage(1);
    }, [filter]);

    useEffect(() => {
        paginate(currentPage);
    }, [filteredData]);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await _get("delivery");
            if (response.data.status === "success") {
                const responseData = response.data.data || [];
                console.log('responseData', responseData)
                setData(responseData);
                filterUsers(responseData);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const filterUsers = (data) => {
        let filtered = [...data];
        if (filter !== 'all') {
            filtered = filtered.filter(user => user.status === filter);
        }
        setFilteredData(filtered);
    };

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        const indexOfLastRecord = pageNumber * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        setUserList(filteredData.slice(indexOfFirstRecord, indexOfLastRecord));
    };

    const deleteDeliveryBoy = async () => {
        if (deliveryToDelete) {
            try {
                const response = await _delete(`/delivery/${deliveryToDelete}`);
                if (response.data.status === 'success') {
                    alert('Delivery Boy deleted successfully');
                    setSuccessMsg(response.data.msg);
                    fetchData();
                    handleCloseDeleteModal();
                    setShowModal(true);
                } else {
                    alert(response.data.msg);
                    console.error('Error deleting food:', response.data.msg);
                }
            } catch (error) {
                console.error('Error deleting food:', error);
            }
        }
    };

    const handleSubscribe = async (index, setMessage) => {
        const updatedUsers = [...userList];
        if (updatedUsers[index]) {
            try {
                const subscribe = await _put(`/del/isactive/${updatedUsers[index].id}`, {
                    status: true,
                });
                if (subscribe.data.status === "success") {
                    fetchData();
                    updatedUsers[index].issubscribed = true;
                    setUserList(updatedUsers);
                    setMessage("Successfully subscribed!");
                } else {
                    setMessage("Subscription failed: " + subscribe.data.msg);
                }
            } catch (error) {
                setMessage("Error subscribing user: " + error.message);
            }
        } else {
            setMessage("User not found at index: " + index);
        }
    };

    const handleShowDeleteModal = (deliveryBoyId) => {
        setDeliveryToDelete(deliveryBoyId);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setDeliveryToDelete(null);
        setShowDeleteModal(false);
    };

    const handleUnsubscribe = async (index, setMessage) => {
        const updatedUsers = [...userList];
        if (updatedUsers[index]) {
            try {
                const unsubscribe = await _put(`/del/isactive/${updatedUsers[index].id}`, {
                    status: false,
                });
                if (unsubscribe.data.status === "success") {
                    updatedUsers[index].issubscribed = false;
                    fetchData();
                    setUserList(updatedUsers);
                    setMessage("Successfully unsubscribed!");
                } else {
                    setMessage("Unsubscription failed: " + unsubscribe.data.message);
                }
            } catch (error) {
                setMessage("Error unsubscribing user: " + error.message);
            }
        } else {
            setMessage("User not found at index: " + index);
        }
    };

    const handleEditUser = (user, mode = "edit") => {
        history.push({
            pathname: "/delivery-add",
            state: { user, mode },
        });
    };

    const prevPage = () => {
        if (currentPage > 1) {
            paginate(currentPage - 1);
        }
    };

    const nextPage = () => {
        const totalPages = Math.ceil(filteredData.length / recordsPerPage);
        if (currentPage < totalPages) {
            paginate(currentPage + 1);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div className="container">
                    <Row>
                        <Col sm="12">
                            <Card>
                                <Card.Header className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                                    <div className="mb-3 mb-sm-0">
                                        <Card.Header.Title>
                                            <h4 className="card-title mb-0 daily-tilte">Delivery Boy List</h4>
                                        </Card.Header.Title>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <Link
                                            className="btn btn-sm btn-icon btn-success p-2"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Add"
                                            to="/delivery-add"
                                        >
                                            <span variant="primary">+</span>Add
                                        </Link>
                                    </div>
                                </Card.Header>
                                <Card.Body className="px-0">
                                    <div className="table-responsive">
                                        <table
                                            id="user-list-table"
                                            className="table "
                                            role="grid"
                                            data-toggle="data-table"
                                        >
                                            <thead>
                                                <tr className="ligth text-center">
                                                    <th>Profile</th>
                                                    <th>Name</th>
                                                    <th>Contact</th>
                                                    <th>Route</th>
                                                    <th>Active</th>
                                                    <th min-width="100px">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {userList.length === 0 ? (
                                                    <tr>
                                                        <td colSpan="9" className="text-center no-date pt-5">No data found</td>
                                                    </tr>
                                                ) : (
                                                    userList.map((user, index) => (
                                                        <tr key={index} className="text-center">
                                                            <td style={{width:"5%",marginLeft:"auto",marginRight:'auto'}}>
                                                                <Avatar
                                                                    firstName={user.fname}
                                                                    lastName={user.lname}
                                                                />
                                                            </td>
                                                            <td>{user.fname + " " + user.lname}</td>
                                                            <td>{user.phone}</td>
                                                            <td>
          {user['deliveroute.routeName'] && user['deliveroute.routeName'].length > 0
            ? user['deliveroute.routeName'].map(route => route.routeName).join(', ')
            : 'N/A'}
        </td>
                                                            <td>
                                                                <DeliveryActive
                                                                   isSubscribed={user.is_active} 
                                                                    onSubscribe={(setMessage) =>
                                                                        handleSubscribe(index, setMessage)
                                                                    }
                                                                    onUnsubscribe={(setMessage) =>
                                                                        handleUnsubscribe(index, setMessage)
                                                                    }
                                                                    userName={user.fname + " " + user.lname}
                                                                    userPhone={user.phone}
                                                                    onEditUser={() => handleEditUser(user)}
                                                                    errorMessage={message}
                                                                />
                                                            </td>
                                                            <td>
                                                                <div className="flex align-items-center list-user-action">
                                                                    <Button
                                                                        className="btn btn-sm btn-icon btn-warning m-1"
                                                                        data-toggle="tooltip"
                                                                        data-placement="top"
                                                                        title="Edit"
                                                                        onClick={() => handleEditUser(user, "edit")}
                                                                    >
                                                                        <span className="btn-inner">
                                                                            <svg
                                                                                width="20"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                ></path>
                                                                                <path
                                                                                    fillRule="evenodd"
                                                                                    clipRule="evenodd"
                                                                                    d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                ></path>
                                                                                <path
                                                                                    d="M15.1655 4.60254L19.7315 9.16854"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                ></path>
                                                                            </svg>
                                                                        </span>
                                                                    </Button>
                                                                    <Button
                                                                        className="btn btn-sm btn-icon btn-danger m-2"
                                                                        title="Delete"
                                                                        onClick={() => handleShowDeleteModal(user.id)}
                                                                    >
                                                                        <span className="btn-inner">
                                                                            <svg
                                                                                width="20"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                stroke="currentColor"
                                                                            >
                                                                                <path
                                                                                    d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                ></path>
                                                                                <path
                                                                                    d="M20.708 6.23975H3.75"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                ></path>
                                                                                <path
                                                                                    d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                ></path>
                                                                            </svg>
                                                                        </span>
                                                                    </Button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                                <Card.Footer>
                                    {filteredData.length > recordsPerPage && (
                                        <div className="d-flex justify-content-center">
                                            <Button onClick={prevPage} disabled={currentPage === 1} className="m-2">
                                                Prev
                                            </Button>
                                            <div className="text-center m-2 p-2">
                                                {currentPage}/{Math.ceil(filteredData.length / recordsPerPage)}
                                            </div>
                                            <Button
                                                onClick={nextPage}
                                                disabled={currentPage === Math.ceil(filteredData.length / recordsPerPage)}
                                                className="m-2"
                                            >
                                                Next
                                            </Button>
                                        </div>
                                    )}
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>{successMsg}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this Delivery Boy Account?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteModal}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={deleteDeliveryBoy}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeliveryAllBoyList;
