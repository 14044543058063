import React, { useState } from "react";
import { Row, Col, Form, Button, InputGroup, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Card from "../../../components/card";
import { _post , _delete } from "../../../apiClient";

// img
import fav from "../../../assets/images/Login/SR-Logo.png";

// Validation Schema
const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits')
    .required('Phone number is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
});

const LogOut = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [confirmInput, setConfirmInput] = useState("");  // State to track 'Confirm' input
  const [isDeleteEnabled, setIsDeleteEnabled] = useState(false);  // State to enable/disable the Delete button
  const history = useHistory();

  const handleDelete = async (values) => {
    setValidationMessage(""); 
    setLoading(true);
  
    try {
      const response = await _post("login", values);
      if (response.data.status === 'success') {
        if (response.data.data.role === 'User') {  
          localStorage.setItem("role", response.data.data.role);
          localStorage.setItem("phone", response.data.data.phone);
          localStorage.setItem("token", response.data.data.token);
          setDeleteSuccess(true);  
        } else {
          setValidationMessage("Deletion allowed for 'User' role only.");
        }
      } else {
        setValidationMessage("Login failed: " + response.data.msg);
      }
    } catch (error) {
      setValidationMessage("Error during Delete Account: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFinalDelete = async () => {
    setValidationMessage(""); 
    setLoading(true);
  
    const phone = localStorage.getItem("phone");
  
    try {
      const response = await _delete(`delete/${phone}`);  // Append phone in the URL path
      if (response.data.status === 'success') {
        setValidationMessage("Account successfully deleted.");
        localStorage.removeItem("role");
        localStorage.removeItem("token");
        alert("Account successfully deleted.");
        window.location.reload();
        window.location.replace("https://shreeraghavendararfoods.com/");
      } else {
        setValidationMessage("Failed to delete account: " + response.data.msg);
      }
    } catch (error) {
      setValidationMessage("Error during deletion: " + error.message);
    } finally {
      setLoading(false);
    }
  };
  
  

  // Handle confirm input change
  const handleConfirmInputChange = (e) => {
    const value = e.target.value;
    setConfirmInput(value);
    setIsDeleteEnabled(value === "Confirm");  // Enable the button only when input is "Confirm"
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <section className="container-fluid bg-circle-login" id="auth-sign">
        {!deleteSuccess ? (  
          <Row className="align-items-center" id="Delete-1">
            <Col md="6" lg="6" xl="6">
              <Card.Body className="login-cen">
                <div className="logo-main">
                  <img src={fav} className="img-fluid logo-img" alt="img4" />
                </div>
                <h2 className="mb-2 text-center">Delete Account</h2>
                <p className="text-center">Delete Your Account Here.</p>
                <Card>
                  <Card.Body>
                    <Formik
                      initialValues={{ phone: '', password: '' }}
                      validationSchema={validationSchema}
                      onSubmit={handleDelete}
                    >
                      {({
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        errors,
                        touched,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <Row>
                            <Col lg="12">
                              <Form.Group className="form-group">
                                <Form.Label htmlFor="phone" className="form-title">Mobile</Form.Label>
                                <Form.Control
                                  type="tel"
                                  className={`form-control-sm ${touched.phone && errors.phone ? 'is-invalid' : ''}`}
                                  id="phone"
                                  name="phone"
                                  aria-describedby="phone"
                                  placeholder="Enter a Mobile Number"
                                  value={values.phone}
                                  maxLength={10}
                                  onChange={(e) => {
                                    const value = e.target.value.replace(/\D/g, ''); // Allow only digits
                                    handleChange({
                                      target: {
                                        name: 'phone',
                                        value,
                                      },
                                    });
                                  }}
                                  onBlur={handleBlur}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.phone}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg="12">
                              <Form.Group className="form-group">
                                <Form.Label htmlFor="password" className="form-title">Password</Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    type={passwordVisible ? "text" : "password"}
                                    className={`form-control-sm ${touched.password && errors.password ? 'is-invalid' : ''}`}
                                    id="password"
                                    name="password"
                                    aria-describedby="password"
                                    placeholder="Enter a Password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.password && !!errors.password}
                                  />
                                  <InputGroup.Text onClick={togglePasswordVisibility} style={{ cursor: "pointer" }}>
                                    {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                                  </InputGroup.Text>
                                  <Form.Control.Feedback type="invalid">
                                    {errors.password}
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Col lg="12" className="d-flex justify-content-center">
                            {validationMessage && (
                              <p className="text-danger">{validationMessage}</p>
                            )}
                          </Col>
                          <div className="d-flex justify-content-center pt-5">
                            <Button type="submit" variant="saffron" disabled={isSubmitting || loading}>
                              {loading ? <Spinner animation="border" size="sm" /> : "Verify"}
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Col>
            <Col md="6" lg="6" xl="6" className="d-lg-block d-none vh-100 overflow-hidden"></Col>
          </Row>
        ) : (  
          <Row className="align-items-center" id="Delete-2">
            <Col md="6" lg="6" xl="6">
              <Card.Body className="login-cen">
                <div className="logo-main">
                  <img src={fav} className="img-fluid logo-img" alt="img4" />
                </div>
                <h2 className="mb-2 text-center">Delete Account</h2>
                <Card>
                  <Card.Body>
                    <Form>
                      <h5 className="text-center">Are you sure you want to delete your account?</h5>
                      <p>Once you confirm, all content in this account will be permanently deleted. Deleted accounts cannot be recovered.</p>
                      <p><b>Note: All associated data will be permanently removed from our server within 7 to 10 days.</b></p>
                      <Row>
                        <Col lg="12">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="confirm" className="form-title">Please enter "Confirm"</Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control-sm"
                              id="confirm"
                              name="confirm"
                              placeholder="Confirm"
                              value={confirmInput}
                              onChange={handleConfirmInputChange}  // Update input handler
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-center pt-2">
                        <Button 
                          variant="saffron" 
                          disabled={!isDeleteEnabled || loading}  // Enable/Disable button based on input
                          onClick={handleFinalDelete}  // Call final delete function
                        >
                          {loading ? <Spinner animation="border" size="sm" /> : "Delete"}
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Col>
            <Col md="6" lg="6" xl="6" className="d-lg-block d-none vh-100 overflow-hidden"></Col>
          </Row>
        )}
      </section>
    </>
  );
};

export default LogOut;
