import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import Card from "../../../components/card";
import { _get } from "../../../apiClient";
import Avatar from "./avatar/avatar";
import Loader from "../Loader";
import DeliveryStatus from "./Pipe/status";

const DeliveryDetails = () => {
    const [detailList, setDetailList] = useState([]);
    const [deliverBoy, setDeliveryBoy] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const { delivery, mode } = location.state || {};

    const history = useHistory();
    const token = localStorage.getItem("token");

    useEffect(() => {
        if (token) {
            fetchDetail();
        }
    }, [token]);

    const isViewMode = mode === "view";
    const fetchDetail = async () => {
        setIsLoading(true);
        try {
            if (isViewMode && delivery && delivery.deliveryBoy && delivery.deliveryBoy.id) {
                console.log('delivery-list', delivery);
                const routeid= delivery.id;
                const response = await _get(
                    `deliverylist/${delivery.deliveryBoy.id}?foodType=dinner&routeid=${routeid}`
                );
    
                if (response.data.status === "success") {
                    setDetailList(response.data.data.users);
                    setDeliveryBoy(delivery.deliveryBoy);
                }
            } else {
                console.warn('Invalid delivery object:', delivery);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };
    
    

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div className="container">
                    <h4 className="card-title mb-0 daily-tilte mb-5">Delivery Boy Details</h4>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <Card.Header className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                                    <div className="mb-3 mb-sm-0">
                                        <Card.Header.Title>
                                            <h4 className="card-title mb-0 daily-tilte">{deliverBoy.fname +" "+ deliverBoy.lname}</h4>
                                        </Card.Header.Title>
                                    </div>
                                </Card.Header>
                                <Card.Body className="px-0">
                                    <div className="table-responsive">
                                        <table
                                            id="user-list-table"
                                            className="table"
                                            role="grid"
                                            data-toggle="data-table"
                                        >
                                            <thead>
                                                <tr className="ligth text-center">
                                                    <th>Profile</th>
                                                    <th>Name</th>
                                                    <th>Contact</th>
                                                    <th>Reason</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {detailList.length === 0 ? (
                                                    <tr>
                                                        <td colSpan="4" className="text-center no-date pt-5">
                                                            No data found
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    detailList.map((user, index) => (
                                                        <tr key={index} className="text-center">
                                                            <td style={{width:"5%",marginLeft:"auto",marginRight:'auto'}}>
                                                                <Avatar
                                                                    firstName={user.fname}
                                                                    lastName={user.lname}
                                                                />
                                                            </td>
                                                            <td>{user.fname + " " + user.lname}</td>
                                                            <td>{user.phone}</td>
                                                            <td>{user.reason || 'N/A'}</td>
                                                            <td><DeliveryStatus status={user.delivery_status}/></td>
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default DeliveryDetails;
